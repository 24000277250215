/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description insurance souscription
 * @component AssurancePreview
 */
import { useDispatch } from "react-redux";
import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import beneficiaire from "../../../../../assets/beneficiary.png";
import form from "../../../../../assets/beneficiaire.png";
import terms from "../../../../../assets/terms.png";
import contrat from "../../../../../assets/contrat.png";
import payment from "../../../../../assets/payment.png";
import recap from "../../../../../assets/recap.png";
import ProcessHeader from "../../../header/ProcessHeader";

import Button from "../../../../../UI/button/Button";

import {
	usePayementMutation,
	useWalletPayementMutation,
	// useGetAssuranceByIdQuery,
	useGetFormQuestionByIdQuery,
} from "../../../../../features/api/apiSlice";

import { setOperation } from "../../../../../features/user/payement.slice";

import Membres from "./Membres";
import ConditionStep from "./ConditionStep";
import ContratStep from "./ContratStep";
import FormulaireStep from "./FormulaireStep";
import PayementStep from "./PayementStep";
import Spinner from "../../../../../UI/spinner/Spinner";
import Recapdocumentstep from "./Recapdocumentstep";

// steps
const InitialSteps = {
	currentStep: "01",
	steps: [
		{ id: "01", name: "Questionnaire", status: "current" },
		{ id: "02", name: "Bénéficiaires", status: "upcoming" },
		{ id: "03", name: "Conditions d'utilisations", status: "upcoming" },
		{ id: "04", name: "Contrats", status: "upcoming" },
		{ id: "05", name: "Recapdocument", status: "upcoming" },
		{ id: "06", name: "Payement", status: "upcoming" },
	],
};

// step reducer
const stepReducer = (state, action) => {
	switch (action.type) {
		case "COMPLETE":
			const changeStep = state.steps.map((item, index) => {
				if (index === action.step.index) {
					return {
						...item,
						status: "complete",
					};
				}
				return {
					...item,
					status: "upcoming",
				};
			});
			return {
				steps: changeStep,
				currentStep: action.step.id,
			};
		case "CURRENT":
			const nextStep = state.steps.map((item, index) => {
				if (index < action.step.index) {
					return {
						...item,
						status: "complete",
					};
				} else if (index === action.step.index) {
					return {
						...item,
						status: "current",
					};
				} else {
					return {
						...item,
						status: "upcoming",
					};
				}
			});
			return {
				steps: nextStep,
				currentStep: action.step.id,
			};

		default:
			break;
	}
};

const AssurancePreview = () => {
	const params = useParams();
	const dispatch = useDispatch();

	// get insurance request
	const {
		data: assurance,
		isSuccess,
		isFetching,
	} = useGetFormQuestionByIdQuery(params?.id);

	// pay product insurance request
	const [payementFetch, { isLoading }] = usePayementMutation();
	const [walletPayementFetch, { WP_isLoading }] = useWalletPayementMutation();
	const [totalPrice, settotalPrice] = useState();

	let data;
	let price =
		parseFloat(assurance?.data?.prix) +
		parseFloat(assurance?.data?.souscription.supplement);
	useEffect(() => {
		settotalPrice(parseFloat(price));
	}, [assurance?.data]);

	if (isSuccess) {
		data = assurance?.data;
	}
	// settotalPrice(data?.prix)
	// insurance price
	console.log("données",data)
	const [IsLoading, setIsLoading] = useState(false);
	const [description, setdescription] = useState(data?.description);
	const [type_contrat, settype_contrat] = useState(data?.type_contrat);
	const [dure, setduree] = useState(data?.duree);
	const [nom, setnom] = useState(data?.nom);
	const [fournisseur, setfournisseur] = useState(data?.fournisseur);
	const [categorie, setcategorie] = useState(data?.categorie);

	const [methodePayement, setMethodePayement] = useState("CM_ORANGEMONEY");

	const [recever, setRecever] = useState([]);

	// form answer state
	const [formAnswer, setFormAnswer] = useState([]);

	// step state
	const [stepState, dispatchStep] = useReducer(stepReducer, InitialSteps);

	// step destructuration
	const { steps, currentStep } = stepState;
	// const { steps } = stepState;
	// let currentStep = "01";
	// set step
	const setStep = (step, index) => {
		dispatchStep({ type: "CURRENT", step: { ...step, index: index } });
	};

	// content
	let content;
	let image;

	switch (currentStep) {
		case "01":
			// form
			content = (
				<FormulaireStep
					{...data}
					setStep={setStep}
					steps={steps}
					settotalPrice={settotalPrice}
					setFormAnswer={setFormAnswer}
					setIsLoading={setIsLoading}
				/>
			);
			image = <img src={form} alt="beneficiare" width="1500px" />;
			break;
		case "02":
			// member
			content = (
				<Membres
					setRecever={setRecever}
					recever={recever}
					setStep={setStep}
					steps={steps}
				/>
			);
			image = <img src={beneficiaire} alt="beneficiare" width="1500px" />;
			break;
		case "03":
			// condition
			content = <ConditionStep {...data} setStep={setStep} steps={steps} />;
			image = <img src={terms} alt="terms" style={{ marginTop: "200px" }} />;
			break;
		case "04":
			// contrat
			content = <ContratStep {...data} setStep={setStep} steps={steps} />;
			image = <img src={contrat} alt="contrat" />;
			break;
		case "05":
			// recapitulatif
			content = (
				<Recapdocumentstep
					{...data}
					setStep={setStep}
					steps={steps}
					setdescription={setdescription}
					setfournisseur={setfournisseur}
					setcategorie={setcategorie}
					// setfournisseur={setfournisseur}
					setnom={setnom}
					setduree={setduree}
					settype_contrat={settype_contrat}
					formAnswer={formAnswer}
				/>
			);
			image = <img src={recap} alt="recap" />;
			break;
		case "06":
			// payement
			content = (
				<PayementStep
					{...data}
					setMethodePayement={setMethodePayement}
					setStep={setStep}
					steps={steps}
				/>
			);
			image = <img src={payment} alt="contrat" />;
			break;
		default:
			break;
	}

	// handle pay
	const handlePayement = async (e) => {
		e.preventDefault();

		const beneficiaires = recever?.map((item) => {
			return item.cedoMenbre;
		});

		const payement = {
			produits: data?.id_produit,
			prix: totalPrice || data.prix,
			operator: methodePayement,
			form: formAnswer,
			beneficiaires,
			id_souscription: data?.souscription.id,
		};

		if (methodePayement === "PORTE_FEUILLE") {
			// pay with pocket
			try {
				const payload = await walletPayementFetch(payement).unwrap();
				console.log(payload);
				// wait thing i can do with the value of payloaad
			} catch (err) {
				throw new Error("Erreur");
			}
		} else {
			// pay whithout pocket
			try {
				const payload = await payementFetch(payement).unwrap();
				dispatch(setOperation("souscription"));
				window.open(payload.url, "_self");
			} catch (err) {
				throw new Error("Erreur");
			}
		}
	};

	return (
		<div>
			{/* process header  */}
			<div className="hidden">
				<ProcessHeader steps={steps} setStep={setStep} />
			</div>
			{isFetching ? (
				<div className="flex items-center justify-center mt-24">
					<Spinner className="h-10 w-10 text-primary-700 m-8" />
				</div>
			) : (
				<div className="sm:px-4 px-4 sm:mx-4 sm:mt-4 grid grid-cols-6 gap-6">
					<div className="col-span-6 sm:col-span-4 lg:col-span-4">{content}</div>

					{/* pay card  */}

					<div
						className={clsx(
							"col-span-6 sm:col-span-2 lg:col-span-2 sm:mx-8 lg:mx-1 mt-5 "
						)}
					>
						{currentStep !== "02" && currentStep !== "03" && (
							<div className="h-fit divide-y border rounded-md shadow-sm">
								<div className="flex justify-between items-center py-4 px-2">
									<span className=" text-gray-500 text-md">
										Coût total <small>(fcfa)</small>{" "}
									</span>
									<span className="text-secondary-500 text-2xl text-medium">
										{IsLoading ? (
											<Spinner className="h-4 w-4 text-primary-700 m-8 " />
										) : (
											totalPrice
										)}
									</span>
								</div>
								<div className="flex justify-between items-center py-4 px-2">
									<span className=" text-gray-500 text-md">
										A partir de <small>(fcfa)</small>
									</span>
									<span className="text-gray-700 text-lg text-medium">
										{data?.prix}{" "}
									</span>
								</div>
								{currentStep === "06" && (
									<div className="flex justify-between items-center py-4 px-2">
										<Button
											children="Souscrire maintenant"
											button="primary"
											type="button"
											loading={isLoading}
											onClick={handlePayement}
										/>
									</div>
								)}
							</div>
						)}

						<div className="mt-9">{image}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AssurancePreview;
