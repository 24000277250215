/**
 * @author assan zidan
 * @description route configuration
 * @email nsangouassanzidan@gmail.com
 */
import { USERCODE } from "./config/config";

import { Routes, Route } from "react-router-dom";
import Login from "./Pages/auth/Login";
import Registration from "./Pages/auth/Registration";
import ForgetPassword from "./Pages/auth/ForgetPassword";
import Layout from "./components/layouts/index.js";
import ValidateEmail from "./Pages/auth/ValidateEmail";
import VerifCodePage from "./Pages/auth/VerifCodePage";
import NewPassword from "./Pages/auth/NewPassword";
import Page404 from "./Pages/404";
import UserProfilPage from "./Pages/dashboard/UserProfil";
import UserRechargePage from "./Pages/dashboard/UserRecharge";
import FicheRDVPage from "./Pages/consultations/client/FicheRDV";
import ConsultAvecAssurPage from "./Pages/consultations/client/ConsultAvecAssurPage";
import ChoixMedecinPage from "./Pages/consultations/client/ChoixMedecinPage";
import MotifConsultationPage from "./Pages/consultations/client/MotifConsultationPage";
import SpecialiteConsultationPage from "./Pages/consultations/client/SpecialiteConsultationPage";
import MonAgendaPage from "./Pages/consultations/doctor/MonAgenda";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

// Categories assurance
import CategoriesAssurances from "./components/layouts/main/parametre/categoriesAssurance/CategoriesAssurances";
import AddCategory from "./components/layouts/main/parametre/categoriesAssurance/AddCategory";
import ViewCategory from "./components/layouts/main/parametre/categoriesAssurance/ViewCategory";

import ListeDesMotifsPage from "./Pages/consultations/doctor/ListeDesMotifs";
import MesConsultationsPage from "./Pages/consultations/doctor/MesConsultations";
import SingleConsultationsPage from "./Pages/consultations/doctor/SingleConsultationsPage";
import MesCompetences from "./Pages/consultations/doctor/MesCompetences";
import AddCompetences from "./Pages/consultations/doctor/AddCompetences";
import Dashboard from "./Pages/dashboard/Index";
import ListAssurancesPage from "./Pages/assurances/clients/ListAssurances";
import ListCategoriePage from "./Pages/assurances/clients/ListCategoriePage";
import ListSinistre from "./Pages/assurances/provider/listSinistrepage";
import ListAssuranceByIdCategorie from "./Pages/assurances/clients/ListAssuranceByIdCategorie";
import AssurancesInchSouscribePage from "./Pages/assurances/clients/AssurancesInchSouscribePage";
import AssurancePreviewPage from "./Pages/assurances/clients/AssurancePreviewPage";
import ProviderAssurance from "./Pages/assurances/provider/ListAssurances";
import ProviderListAssurancesOwner from "./Pages/assurances/provider/ListAssurancesOwnerPage";
import AddAssurancePage from "./Pages/assurances/provider/AddAssurancePage";
import MesProduitsPage from "./Pages/assurances/clients/MesProduits";
import ListMembers from "./Pages/users/ListMembersPage";
import AddpaiementMode from "./Pages/user/addmodepaiementpage";
import SetModepaiement from "./Pages/user/setmodepaiementpage";
import Addmember from "./Pages/users/AddMembersPage";
import ListUsersPage from "./Pages/users/ListUsersPage";
import ReglementPage from "./Pages/user/ReglementPage";
import UserPreviewPage from "./Pages/users/UserPreviewPage";
import UserAddPage from "./Pages/users/UserAddPage";
import AddMotifPage from "./Pages/consultations/doctor/AddMotif";
import UpdateMotifPage from "./Pages/consultations/doctor/UpdateMotif";
import RequireAuth from "./components/layouts/main/requireAuth/RequireAuth";
import Islogin from "./components/layouts/main/requireAuth/Islogin";
import Unauthorized from "./Pages/Unauthorized";
import CanalPage from "./Pages/consultations/client/CanalPage";
import InchproAssur from "./components/layouts/main/consultations/InchproAssur";
import SetAssurancePage from "./Pages/assurances/provider/SetAssurancePage";
import SetAssuranceDetails from "./Pages/assurances/provider/SetAssuranceDetail";
import VillePage from "./Pages/consultations/client/VillePage";
import paiementMode from "./Pages/user/paiementMode";
import Competences from "./Pages/consultations/client/Competences";
import AvisExpertPage from "./Pages/consultations/doctor/AvisExpertPage";
import SkillsExpertPage from "./Pages/consultations/doctor/SkillsExpertPage";
import ChoixExpertPage from "./Pages/consultations/client/ChoixExpertPage";
import MesDemandeAvisPage from "./Pages/consultations/doctor/MesDemandeAvisPage";
import MesAvisExpertPage from "./Pages/consultations/doctor/MesAvisExpertPage";
import UserTransactionPage from "./Pages/user/UserTransactionPage";
import Modepaiementpage from "./Pages/user/Modepaiementpage";
import SinistresPage from "./Pages/SinistresPage";
import AddAssuranceDoctorPage from "./Pages/assurances/provider/AddAssuranceDoctorPage";
import SinistreAdminPage from "./Pages/SinistreAdminPage";
import IncidentAdminPage from "./Pages/IncidentAdminPage";
import AdminTransactionPage from "./Pages/user/AdminTransactionPage";
import EchangePage from "./Pages/EchangePage";
import MesCompetencesPage from "./Pages/consultations/doctor/MesCompetences";
import CompetencesPage from "./Pages/consultations/doctor/Competences";
import AddCompetencesAdminPage from "./Pages/consultations/doctor/AddCompetencesAdmin";
import UpdateCompetencesPage from "./Pages/consultations/doctor/UpdateCompetence";
import MotifAddPage from "./components/layouts/main/consultations/MotifAddPage";
import MotifPreviewPage from "./components/layouts/main/consultations/MotifPreviewPage";
import ConsultDetailPage from "./Pages/user/ConsultDetailPage";
function App() {
  const { ADMIN, ENTREPRISE, CLIENT, DOCTOR, FAMILY, INSURER } = USERCODE;

  return (
    <>
      <Routes>
        {/* public routes  */}
        {/* domaineName/ or domaineName   */}
        <Route path="/" element={<Login />} />
        {/*  */}
        {/* page not found  */}
        <Route path="*" element={<Page404 />} />
        {/* unauthorized page: domaineName/unauthorized */}
        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route element={<Islogin />}>
          {/* login page : domaineName/connexion */}
          <Route path="/connexion" element={<Login />} />
          {/* signup page : domaineName/inscription */}
          <Route path="/inscription" element={<Registration />} />
        </Route>

        {/* forgot password page : domaineName/mot-de-passe-oublier*/}
        <Route path="/mot-de-passe-oublier" element={<ForgetPassword />} />
        {/* validation email page : domaineName/validation/:token */}
        <Route path="/validation/:token" element={<ValidateEmail />} />
        {/* verif code  page : domaineName/verifcode */}
        <Route path="/verifcode" element={<VerifCodePage />} />
        {/* new password page : domaineName/nouveau-mot-passe/:token */}
        <Route path="/nouveau-mot-passe/:token" element={<NewPassword />} />

        {/* admin routes  */}
        <Route element={<RequireAuth allowedRoles={ADMIN} />}>
          {/* users  */}
          <Route path="/users" element={<Layout />}>
            {/* users  page : domaineName/users */}
            <Route index element={<ListUsersPage />} />
            {/* user page : domaineName/users/:code */}
            <Route path=":code" element={<UserPreviewPage />} />
            {/* add mode paiement page : domaineName/users/a */}
            <Route path="addpaiementMode" element={<addpaiementMode />} />
            {/* add user page : domaineName/users/adduser */}
            <Route path="adduser" element={<UserAddPage />} />
          </Route>
          <Route path="/consultations" element={<Layout />}>
            {/* update competence page : domaineName/consultations/competence/:id */}
            <Route
              path="competence/update/:id"
              element={<UpdateCompetencesPage />}
            />

            {/* add competence page : domaineName/consultations/addcompetence_admin  */}
            <Route
              path="addcompetence_admin"
              element={<AddCompetencesAdminPage />}
            />
            {/* my skills page : domaineName/consultations/les-competences   */}
            <Route path="les-competences" element={<CompetencesPage />} />
          </Route>

          {/* incident  */}
          <Route path="/all-incidents" element={<Layout />}>
            {/* all incident page : domaineName/all-incidents  */}
            <Route index element={<IncidentAdminPage />} />
          </Route>

          {/* insurance  */}

          <Route path="/all-assurances" element={<Layout />}>
            {/* all insurance page : domaineName/all-assurances  */}

            <Route index element={<ProviderAssurance />} />
            {/* new insurance page : domaineName/all-assurances/nouveau  */}
            <Route path="nouveau" element={<AddAssurancePage />} />
            {/* insurance page : domaineName/all-assurances/:code  */}
            <Route path=":code" element={<SetAssurancePage />} />
          </Route>
          <Route path="/add-doctor" element={<Layout />}>
            {/* add doctor insurer page : domaineName/add-doctor  */}
            <Route index element={<AddAssuranceDoctorPage />} />
          </Route>

          {/* transaction  */}
          <Route path="/all-operations" element={<Layout />}>
            {/* all transaction page : domaineName/all-operations  */}
            <Route index element={<AdminTransactionPage />} />
             {/* take rdv page : domaineName/operations/details  */}
             <Route path="Consultations/:code" element={<SingleConsultationsPage />} /> 
             {/* take rdv page : domaineName/operations/details  */}
             <Route path="mes-assurances/:code" element={<SetAssuranceDetails />} /> 
             {/* <Route path="/mes-assurances/:code" element={<DetailAssurance />} />  */}
          </Route>

          {/* sinistre  */}
          <Route path="/assurances/all-sinistres" element={<Layout />}>
            {/* all sinistre page : domaineName/assurances/all-sinistres  */}
            <Route index element={<SinistreAdminPage />} />
          </Route>

          {/* listedes motifs  */}
          <Route path="/mes-liste-des-motifs" element={<Layout />}>
            {/* all sinistre page : domaineName/assurances/all-sinistres  */}
            <Route index element={<ListeDesMotifsPage />} />
          </Route>

          {/* add motif page : domaineName/motifs/addmotif */}
          <Route path="mes-liste-des-motifs/addmotif" element={<AddMotifPage />} />

          {/* consultation  */}
          <Route path="/Consultations/listes-des-motifs" element={<Layout />}>
            <Route index element={<ListeDesMotifsPage />} />
          </Route>

          <Route
            path="/Consultations/listes-des-motifs/addmotif"
            element={<Layout />}
          >
            <Route index element={<MotifAddPage />} />
          </Route>

          <Route
            path="/Consultations/listes-des-motifs/:id_motif"
            element={<Layout />}
          >
            <Route index element={<MotifPreviewPage />} />
          </Route>
        </Route>

        {/* updatemotif  */}
        <Route path="mes-liste-des-motifs/:id_motif" element={<Layout />}>
          {/* all transaction page : domaineName/all-operations  */}
          <Route index element={<UpdateMotifPage />} />
        </Route>

        {/* Familly and ENTREPRISE routes  */}
        <Route element={<RequireAuth allowedRoles={[FAMILY, ENTREPRISE]} />}>
          <Route path="/listmembers" element={<Layout />}>
            {/* member page : domaineName/listmembers  */}
            <Route index element={<ListMembers />} />
            {/* FAMILY route  */}
            {/* add member page : domaineName/listmembers/addmember  */}
            <Route path="addmember" element={<Addmember />} />
            {/* ENTREPRISE route  */}
            {/* add member page : domaineName/listmembers/adduser  */}
            <Route path="adduser" element={<UserAddPage />} />
          </Route>
        </Route>

        {/* Insurer  route  */}
        <Route element={<RequireAuth allowedRoles={[INSURER, ADMIN]} />}>
          {/* insurances page : domaineName/mes-assurances  */}

          <Route path="/mes-assurances" element={<Layout />}>
            {/* insurances page : domaineName/mes-assurances  */}
            <Route index element={<ProviderListAssurancesOwner />} />

            {/* new insurance page : domaineName/mes-assurances/nouveau  */}
            <Route path="nouveau" element={<AddAssurancePage />} />
            {/*  insurance page : domaineName/mes-assurances/:code  */}
            {/* <Route path=":code" element={<SetAssurancePage />} /> j'ai commenter ceci pour retourner une nouvelle page de detail de l'assurance */}
            <Route path=":code" element={<SetAssuranceDetails />} />
          </Route>
          {/* add doctor insurer page : domaineName/add-doctor  */}
          <Route path="/add-doctor" element={<Layout />}>
            <Route index element={<AddAssuranceDoctorPage />} />
          </Route>
        </Route>

        {/*  medecin route  */}
        <Route element={<RequireAuth allowedRoles={[DOCTOR]} />}>
          <Route path="/consultations" element={<Layout />}>
            {/* mes-demandes-avis page : domaineName/consultations/mes-demandes-avis  */}
            <Route path="mes-demandes-avis" element={<MesDemandeAvisPage />} />

            {/* my skills page : domaineName/consultations/competences   */}
            <Route path="competences" element={<MesCompetencesPage />} />

            {/* demandes-avis page : domaineName/consultations/mes-demandes-avis/:code  */}
            <Route
              path="mes-demandes-avis/:code"
              element={<SingleConsultationsPage />}
            />
            {/* mes-avis-expert page : domaineName/consultations/mes-avis-expert  */}
            <Route path="mes-avis-expert" element={<MesAvisExpertPage />} />
            {/* avis-expert page : domaineName/consultations/mes-avis-expert/:code  */}
            <Route
              path="mes-avis-expert/:code"
              element={<SingleConsultationsPage />}
            />

            {/* agenda page : domaineName/consultations/mon-agenda  */}
            <Route path="mon-agenda" element={<MonAgendaPage />} />
            {/* add competence page : domaineName/consultations/addcompetence  */}
            <Route path="addcompetence" element={<AddCompetences />} />

            {/* list skill expert page : domaineName/consultations/:code/avis-expert/skills  */}
            <Route path=":code/avis-expert/skills" element={<SkillsExpertPage />} />
            {/* list expert by skill page : domaineName/consultations/:code/expert/:idSkill  */}
            <Route path=":code/expert/:idSkill" element={<ChoixExpertPage />} />
            {/* avis-expert page : domaineName/consultations/:code/avis-expert  */}
            <Route path=":code/avis-expert" element={<AvisExpertPage />} />
          </Route>
        </Route>

        {/* dashboard */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[CLIENT, FAMILY, ENTREPRISE, ADMIN, DOCTOR, INSURER]}
            />
          }
        >
          <Route path="/sinistrebyassurance" element={<Layout />}>
            {/* insurances page : domaineName/mes-assurances  */}
            <Route index element={<ListSinistre />} />
          </Route>
          <Route path="/dashboard" element={<Layout />}>
            {/* dashboard page : domaineName/dashboard  */}
            <Route index element={<Dashboard />} />
            {/* help page : domaineName/dashboard/aide  */}
            <Route path="aide" element={<Dashboard />} />
            {/* parametre page : domaineName/dashboard/parametre  */}
            <Route path="parametre" element={<Dashboard />} />

            <Route path="Categories-Assurances" element={<CategoriesAssurances />} />

            {/* regle-confidentialite page : domaineName/dashboard/regle-confidentialite  */}
            <Route path="regle-confidentialite" element={<Dashboard />} />
            {/* mon-compte page : domaineName/dashboard/mon-compte  */}
            <Route path="mon-compte" element={<UserProfilPage />} />
            {/* recharge page : domaineName/dashboard/recharge  */}
            <Route path="recharge" element={<UserRechargePage />} />
          </Route>
        </Route>

        {/* Assurance   */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[CLIENT, DOCTOR, FAMILY, ENTREPRISE, ADMIN]}
            />
          }
        >
          <Route path="/assurances" element={<Layout />}>
            {/* list insurance page : domaineName/assurances/souscrire  */}
            <Route path="souscrire" element={<ListAssurancesPage />} />

            {/* insurance sinistres page : domaineName/assurances/sinistres  */}
            <Route path="sinistres" element={<SinistresPage />} />
            {/* my insurance product page : domaineName/assurances/mes-produits  */}
            <Route path="mes-produits" element={<MesProduitsPage />} />
            {/* insurance souscription page : domaineName/assurances/:id  */}
            <Route path=":id" element={<AssurancePreviewPage />} />
            {/* insurance inch page : domaineName/assurances/inchpro  */}
            <Route path="inchpro" element={<InchproAssur />} />
            <Route path="categorie">
              {/* all insurances categorie page : domaineName/assurances/categorie  */}
              <Route index element={<ListCategoriePage />} />
              {/* all insurances by categorie page : domaineName/assurances/categorie/:id  */}
              <Route path=":id" element={<ListAssuranceByIdCategorie />} />
            </Route>
          </Route>
        </Route>

        {/* consultations  */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[CLIENT, FAMILY, ENTREPRISE, ADMIN, DOCTOR, INSURER]}
            />
          }
        >
          <Route path="/consultations" element={<Layout />}>
            {/* take rdv page : domaineName/consultations/prendre-rdv  */}
            <Route path="prendre-rdv" element={<ConsultAvecAssurPage />} />
            {/* fiche rdv page : domaineName/consultations/fiche_rdv  */}
            <Route path="fiche_rdv" element={<FicheRDVPage />} />
            {/* take rdv with ada insurance page : domaineName/consultations/assurance-ada  */}
            <Route path="assurance-ada" element={<AssurancesInchSouscribePage />} />
            {/* ville page : domaineName/consultations/ville  */}
            <Route path="ville" element={<VillePage />} />
            {/* consult with insurance page : domaineName/consultations/consult-avec-assur  */}
            <Route path="consult-avec-assur" element={<ConsultAvecAssurPage />} />
            {/* motif page : domaineName/consultations/motif  */}
            <Route path="motif" element={<MotifConsultationPage />} />
            {/* skills page : domaineName/consultations/specialite  */}
            <Route path="specialite" element={<SpecialiteConsultationPage />} />
            {/* medecin page : domaineName/consultations/medecin  */}
            <Route path="medecin" element={<ChoixMedecinPage />} />
            {/* channel page : domaineName/consultations/canal  */}
            <Route path="canal" element={<CanalPage />} />

            <Route path="mes-consultations">
              {/* my consultations page : domaineName/consultations/mes-consultations  */}
              <Route index element={<MesConsultationsPage />} />

              {/* my consultation page : domaineName/consultations/mes-consultations/:code  */}
              <Route path=":code" element={<SingleConsultationsPage />} />
            </Route>
          </Route>
        </Route>
        
        {/* dashboard */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[CLIENT, FAMILY, ENTREPRISE, ADMIN, DOCTOR, INSURER]}
            />
          }
        >
          <Route path="/dashboard" element={<Layout />}>
            {/* dashboard page : domaineName/dashboard  */}
            <Route index element={<Dashboard />} />
            {/* help page : domaineName/dashboard/aide  */}
            <Route path="aide" element={<Dashboard />} />
            {/* regle-confidentialite page : domaineName/dashboard/regle-confidentialite  */}
            <Route path="regle-confidentialite" element={<Dashboard />} />
            {/* mon-compte page : domaineName/dashboard/mon-compte  */}
            <Route path="mon-compte" element={<UserProfilPage />} />
            {/* recharge page : domaineName/dashboard/recharge  */}
            <Route path="recharge" element={<UserRechargePage />} />
          </Route>
        </Route>

        {/* Operation and echanges routes  */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[CLIENT, FAMILY, ENTREPRISE, ADMIN, DOCTOR, INSURER]}
            />
          }
        >
          {/* operation  */}
          <Route path="operations" element={<Layout />}>
            {/* operation page : domaineName/operations  */}
            <Route index element={<UserTransactionPage />} />

            {/* reglements page : domaineName/reglements/reglements  */}
            <Route path="reglements" element={<ReglementPage />} />

            <Route path="modepaiement" element={<paiementMode />} />
            <Route path="Modepaiement" element={<Modepaiementpage />} />

            <Route path=":id_modePaiement" element={<SetModepaiement />} />
            <Route path="addmode" element={<AddpaiementMode />} />
            
            <Route path="mes-consultations">
              {/* my consultations page : domaineName/consultations/mes-consultations  */}
              <Route index element={<MesConsultationsPage />} />

              {/* my consultation page : domaineName/consultations/mes-consultations/:code  */}
              <Route path=":code" element={<SingleConsultationsPage />} />
            </Route>
           
            
          </Route>

          {/* echanges  */}
          <Route path="echanges" element={<Layout />}>
            {/* reglements page : domaineName/echanges  */}
            <Route index element={<EchangePage />} />
          </Route>
        </Route>

		{/* Parametres routes */}
		<Route
			element={
				<RequireAuth allowedRoles={
					[CLIENT, FAMILY, ENTREPRISE, ADMIN, DOCTOR, INSURER]
				} />
			}
		>
			<Route path="parametres">
				{/* Categorie-assurances route */}
				<Route path="categories-assurance" element={<Layout />}>
					<Route index element={<CategoriesAssurances />} />
					<Route path="add" element={<AddCategory />} />
					<Route path="view" element={<ViewCategory />} />
				</Route>
			</Route>
		</Route>
      </Routes>
    </>
  );
}

export default App;
