/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description formulaire de connexion
 * @component LoginForm
 * @props setOpen, setalert, 
 */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import sha256 from "sha256";

import Input from "../../UI/form/Input";
import Button from "../../UI/button/Button";

import useInput from "../../hooks/use-input";
import usePasswordToggle from "../../hooks/usePasswordToggle";

import { isPassword, isEmail } from "../../lib/inputValidation";

import axiosInstance from "../../apis/inchAssur";

import { logIn } from "../../features/user/auth.slice";


const LoginForm = ({ setalert }) => {
  const dispatch = useDispatch();

  const [passwordType, Eye] = usePasswordToggle()


  // pasword control
  const {
    value: enteredpassword,
    isValid: enteredpasswordIsValid,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordHandleChange,
    inputBlurHandler: passwordHandleBlur,
  } = useInput(isPassword);

  //control email
  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailHandleChange,
    inputBlurHandler: emailHandleBlur,
  } = useInput(isEmail);

  // navigate
  const navigate = useNavigate();

  // loading state
  const [loading, setloading] = useState(false);


  const handlerSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: enteredEmail,
      password: sha256(enteredpassword),
    };

    setloading(true);

    axiosInstance
      .post("/account/login", {
        ...data,
      })
      .then((res) => {
        const token = res.data?.token;
        localStorage.setItem("token", token);
        console.log(localStorage.getItem("token"));
        dispatch(logIn(token));

        navigate(`/dashboard`);
      })
      .catch((err) => {
        switch (err?.response?.status) {
          case 406:
            //  do actin 
            break;

          default:
            break;
        }
        setalert((prevState) => {
          return {
            ...prevState,
            isAlert: true,
            error: true,
            text: err.response?.data?.message,
          };
        });
      })
      .finally(() => setloading(false));
  };

  const formIsValid = [enteredEmailIsValid, enteredpasswordIsValid].every(
    (currentValue) => currentValue === true
  );

  return (
    <form
      onSubmit={handlerSubmit}
      method="POST"
      className="space-y-6">
      {/* addresse mail  */}
      <Input
        label="Addresse mail"
        inputError={emailInputHasError}
        placeholder={"entrer votre addresse mail"}
        onChange={emailHandleChange}
        onBlur={emailHandleBlur}
        input={{ id: "email", type: "email", maxLength: "80" }}
        name={"email"}
        disabled={false}
        autoComplete="email"
        required={true}
        errorText={"Addresse mail incorect"}
        ariaInputError="email"
      />

      {/* mot de passe  */}
      <Input
        label="Mot de passe"
        inputError={passwordInputHasError}
        onChange={passwordHandleChange}
        onBlur={passwordHandleBlur}
        placeholder={"entrer votre mot de passe"}
        input={{ id: "password", type: passwordType }}
        name={"password"}
        Eye={Eye}
        autoComplete="current-password"
        disabled={false}
        required={true}
        errorText="le mot de passe doit contenir au moins une majuscule, une miniscule, un chiffre, ou un caractere speciale #, ?, !, @, $, %, ^, &, *, -, :, ;, <, >... "
        ariaInputError="password"
      />

      <div className="flex items-center justify-end">
        <Link
          to="/mot-de-passe-oublier"
          className="font-medium text-secondary-600 hover:text-secondary-500 sm:text-sm ">
          Mot de passe oublier?
        </Link>
      </div>

      <div>
        <Button
          type="submit"
          children="Se connecter"
          button="primary"
          loading={loading}
          error={!formIsValid}
        />
      </div>
    </form>
  );
};

export default LoginForm;
