/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description form  step for insurance souscription
 * @component FormulaireStep
 */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../../../../UI/form/Input";
import TextArea from "../../../../../UI/form/TextArea";
import RadioGroup from "../../../../../UI/form/RadioGroup";
import CheckBox from "../../../../../UI/form/CheckBox";
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import StepContent from '@mui/material/StepContent';
import Button from "../../../../../UI/button/Button";
import {
	useSouscriptionAnswerQuestionMutation
} from "../../../../../features/api/apiSlice";


const FormulaireStep = (props) => {
	// props
	const { form, steps, setStep, prix, settotalPrice, setFormAnswer, souscription, setIsLoading } = props;
	const navigate = useNavigate();
	// response state
	const [checkedValues, setCheckedValues] = useState([]);
	const [radioValues, setRadioValues] = useState([]);
	const [textValues, setTextValues] = useState();
	const [textareaValues, setTextAreaValues] = useState();
	const [activeStep, setActiveStep] = useState(0);
	// add souscription request
	const [AnswerQuestion, { isLoading: loading }] = useSouscriptionAnswerQuestionMutation()
	setIsLoading(loading)
	let initialsupp = props?.souscription?.supplement
	const [supplement, setSupplement] = useState(initialsupp)
	let price = parseFloat(props?.prix)

	const setValues = (val) =>{
		setSupplement(val)
		settotalPrice(price + val);
	}

	// handle change
	const handleChange = (e, id, item) => {
		const type = e.target.type;
		const value = e.target.value;
		const checked = e.target.checked;
		switch (type) {
			case "radio":
				const optionsRadio = item?.options?.find(
					(item) => item.label === value
				);
				setRadioValues([optionsRadio]);
				break;
			case "text":
				const textOptions = [
					{
						label: value,
						prix: item.options[0]?.prix,
						id: item.options[0]?.id
					},
				];
				setTextValues(textOptions)
				break;
			case "textarea":
				const textAreaOptions = [
					{
						label: value,
						prix: item.options[0]?.prix,
						id: item.options[0]?.id
					},
				];
				setTextAreaValues(textAreaOptions)

				break;
			case "checkbox":
				const text = e.target.id
				const label = text.split("-")[0];
				const values = item?.options?.find((item) => item.label === label);
				if (checked) {

					setCheckedValues([...checkedValues, values]);
				} else {
					setCheckedValues(
						checkedValues.filter((item) => item !== values)
					);

				}
				break;

			default:
				break;
		}
	};

	// 
	const handleNext = (item, id) => {
		console.log(item)
		switch (item.fieldType) {
			case "radio":
				const radioResponse = {
					options: radioValues,
					supplement: supplement

				};
				AnswerQuestion({ id_souscription: souscription.id, id_question: id, data: radioResponse })
					.unwrap()
					.then((res) => {
						const retour = res.data.supplement
						setValues(retour);
					})
				break;
			case "text":
				const textOptions = [
					{
						label: item.options[0].label,
						prix: item.options[0]?.prix,
						id: item.options[0]?.id
					},
				];
				const textResponse = {
					options: textValues ?? textOptions,
					supplement: supplement

				};
				AnswerQuestion({ id_souscription: souscription.id, id_question: id, data: textResponse })
					.unwrap()
					.then((res) => {
						const retour = res.data.supplement
						setValues(retour);;
					})
				break;
			case "textarea":
				const textAreaOptions = [
					{   
						label: item.options[0].label,
						prix: item.options[0]?.prix,
						id: item.options[0]?.id
					},
				];

				const textAreaResponse = {
					options: textareaValues ?? textAreaOptions,
					supplement: supplement

				};
				AnswerQuestion({ id_souscription: souscription.id, id_question: id, data: textAreaResponse })
					.unwrap()
					.then((res) => {
						const retour = res.data.supplement
						setValues(retour);
					})
				break;
			case "checkbox":

				const checkboxResponse = {
					options: checkedValues,
					supplement: supplement

				};
				AnswerQuestion({ id_souscription: souscription.id, id_question: id, data: checkboxResponse })
					.unwrap()
					.then((res) => {
						const retour = res.data.supplement
						setValues(retour);;
					})


				break;
			default:
				break;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// form preview
	const formPreview = (item, index) => {
		switch (item.fieldType) {
			case "text":
				return (
					<Input
						label={item.question}
						onChange={(e) =>
							handleChange(e, item.id_question, item)
						}
						defaultValue={item.options[0].label}
						input={{
							id: `${index}-${item.id}`,
							type: `${item.fieldType}`,
						}}
						name={`${item.id_question}`}
						disabled={false}
						required={false}
					/>
				);

			case "textArea":
				return (
					<TextArea
						name={`question-${item.id_question}`}
						label={item.question}
						rows="6"
						defaultValue={item.options[0].label}
						id={`question-${item.id_question}-${index}`}
						onChange={(e) =>
							handleChange(e, item.id_question, item)
						}
					/>
				);

			case "radio":
				return (
					<RadioGroup
						options={item.options}
						question={item.question}
						id={`${item.id_question}`}
						disabled={false}
						required={false}
						onChange={(e) =>
							handleChange(e, item.id_question, item)
						}

					/>
				);

			case "checkbox":

				return (
					<CheckBox
						options={item.options}
						question={item.question}
						disabled={false}
						required={false}
						id={`${item.id_question}`}
						onChange={(e) =>
							handleChange(e, item.id_question, item)
						}

					/>

				);

			default:
				break;
		}
	};


	useEffect(() => {
		if (!form?.length) {
			setFormAnswer([]);
			settotalPrice(prix);
			setStep(steps[1], 1);
		}
		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>

			<div className="my-4 flex items-center justify-between">
				<Button
					children="Etape précédente"
					type="button"
					onClick={() => navigate(-1)}
				/>

			</div>
			<h2 className="text-2xl font-bold  text-primary-800 sm:text-3xl mb-4">
				{/* <h1 className="text-2xl font-bold  text-primary-800 sm:text-3xl mb-3"> */}
				Remplissez le questionnaire de souscription.

			</h2>

			{/* <p className="text-sm leading-5 text-gray-500">
				Veillez remplir le formulaire ci-dessous.
			</p> */}
			<div>
				<div className="sm:px-8 px-4 mt-4  flex flex-col gap-y-6">
					<Stepper activeStep={activeStep} orientation="vertical">
						{form?.map((item, index) => (
							<Step key={index}>
								<StepLabel>

								</StepLabel>
								<StepContent>
									<div
										className=""
										key={index}>
										{formPreview(item, index)}
									</div>


									<div className="mt-7 flex justify-between">
										<button className="rounded-md flex justify-center py-2 px-4 text-sm font-medium text-secondary-600 hover:text-secondary-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 z-10 disabled:opacity-75 disabled:cursor-not-allowed"
											disabled={index === 0}
											onClick={handleBack}

										>
											<KeyboardDoubleArrowLeft />
										</button>
										<Button
											onClick={() => handleNext(item, item.id_question)}> {index === form.length - 1 ? "Terminer" : "Suivant"}
										</Button>
									</div>
								</StepContent>
							</Step>
						))}
					</Stepper>
					{activeStep === form.length &&
						<div className="mt-7 flex justify-between">
							<button className="rounded-md flex justify-center py-2 px-4 text-sm font-medium text-secondary-600 hover:text-secondary-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 z-10 disabled:opacity-75 disabled:cursor-not-allowed"
								onClick={handleBack}

							>
								<KeyboardDoubleArrowLeft />
							</button>
							<p className="text-sm leading-5 text-gray-500">
								Vous avez achevé le remplissage du formulaire.<br /> cliquez sur suivant pour aller à l'étape suivante.
							</p>

							<Button
								children="Suivant"
								type="submit"
								onClick={() => setStep(steps[1], 1)} />
						</div>
					}

				</div>
			</div>
		</>
	);
};

export default FormulaireStep;
