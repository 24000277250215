/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description fiche consultation
 * @component Fiche
 */
import React from "react";
import {
	MapPinIcon,
	CalendarDaysIcon,
	BanknotesIcon,
	BellIcon,
} from "@heroicons/react/20/solid";

const Fiche = ({
	code,
	ville,
	motif,
	nom,
	prenom,
	periode,
	prix,
	canal,
	description,
	isAssur,
	duree,
	ref,
}) => {
	// get date
	const getDateTimeRdv = (slot) => {
		if (slot) {
			const options = {
				day: "numeric",
				weekday: "long",
				month: "long",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
			};

			const dateRdv = new Date(slot);
			const dateTime = new Intl.DateTimeFormat("fr-FR", options).format(
				dateRdv
			);

			return dateTime;
		}
	};
	return (
		<div className="pt-5 px-4 sm:px-6">
			<div className="grid grid-cols-1 gap-x-4 gap-y-8  sm:grid-cols-2">
				<div className="sm:col-span-1">
					<div className="text-sm font-medium  text-gray-500">
						Identifiant du patient:{" "}
						<span className="text-md ml-11 text-gray-900">
							{code}
						</span>
					</div>
					<div className="text-sm font-medium mt-3  text-gray-500">
						Nom et prénom du patient:{" "}
						<span className="text-md ml-4 text-gray-900">
							{`${nom} ${prenom}`}
						</span>
					</div>
				</div>
				<div className="sm:col-span-1">
				<div className="sm:col-span-1">
					<div className="text-sm font-medium  text-gray-500">
						Canal:{" "}
						<span className="text-md ml-20 text-gray-900">
							{canal}
						</span>
					</div>
					<div className="text-sm font-medium  text-gray-500">
						Durée:{" "}
						<span className="text-md ml-20 text-gray-900">
							{duree} minutes
						</span>
					</div>
				</div>
					
				</div>
				<div className="sm:col-span-1">
					<dt className="text-sm font-medium inline-flex text-gray-500">
						<BellIcon
							className="h-5 w-5 text-red-400"
							aria-hidden="true"
						/>
						Motif de consultation
						
					</dt>
					<dt className="text-md text-gray-900">
							{motif}
					</dt>
					
				</div>
				<div className="sm:col-span-1">
					<dt className="text-sm font-medium inline-flex text-gray-500">
						<CalendarDaysIcon
							className="h-5 w-5 text-secondary-400"
							aria-hidden="true"
						/>
						Jour de consultation
						
					</dt>
					<dt>
					<time dateTime={periode} className="text-md  text-gray-900" >
							{getDateTimeRdv(periode)}{" "}
					</time>
					</dt>
				</div>
				<div className="sm:col-span-1">
					<dt className="text-sm font-medium inline-flex text-gray-500">
						<BanknotesIcon
							className="h-5 w-5 text-yellow-400"
							aria-hidden="true"
						/>
						Prix de la consultation
					</dt>
					<dd className="mt-1 text-lg text-gray-900">
						{prix} XAF{" "}
						<span className="text-sm">
							{isAssur && "(Montant couvert par votre assurance)"}
						</span>
					</dd>
				</div>
				<div className="">
				<dt className="text-sm font-medium inline-flex text-gray-500">
						<MapPinIcon
							className="h-5 w-5 text-green-400"
							aria-hidden="true"
						/>
						Lieu de la consultation
				</dt>		
						<dd className="mt-1 text-sm text-gray-900">{ville}</dd>
					</div>
				
			</div>
		</div>
	);
};

export default Fiche;
