/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description payement  step for insurance souscription
 * @component PayementStep
 */
import Button from "../../../../../UI/button/Button";
import OM from "../../../../../assets/orange-money.png";
import PORTE from "../../../../../assets/portefeuille.png";
import MOMO from "../../../../../assets/mobile-money.png";

// orange image component
const orange = (
  <img
    className="w-70 h-70 object-cover"
    src={OM}
    alt="orange money"
    height="80px"
    width="90px"
  />
);

// mtn image component
const mtn = (
  <img
    className="w-70 h-70 object-cover"
    src={MOMO}
    alt="mobile money"
    height="70px"
    width="100px"
  />
);

// portefeuille image component
const portefeuille = (
  <img
    className="w-70 h-70 object-cover"
    src={PORTE}
    alt="portefeuille"
    height="70px"
    width="100px"
  />
);

// payement methode
const payementMethods = [
  { id: "om", title: "Orange money", value: "CM_ORANGEMONEY", img: orange },
  {
    id: "momo",
    title: "Mtn mobile money",
    value: "CM_MTNMOBILEMONEY",
    img: mtn,
  },
  {
    id: "posa",
    title: "Mon portefeuille",
    value: "PORTE_FEUILLE",
    img: portefeuille,
  },
];

const PayementStep = ({ setMethodePayement, title, setStep, steps }) => {
  // select payement method
  const handleChangeMethodePayement = (e) => {
    setMethodePayement(e.target.value);
  };

  return (
    <>
      <div className="my-4 flex items-center justify-between">
        <Button
          children="Etape précédente"
          type="button"
          className=""
          onClick={() => setStep(steps[4], 4)}
        />
      </div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1
            le
            contrat
            className="text-2xl font-bold tracking-tight text-primary-800 sm:text-3xl mb-8"
          >
            Payer et telecharger le contrat
          </h1>
        </div>
      </div>
      <div className="sm:flex-auto">
        <p className="text-sm leading-5 text-gray-500">
          Choississez votre methode de payement{" "}
        </p>
        <fieldset className="mt-4">
          <legend className="sr-only">methode de payement</legend>
          <div className="space-y-4 sm:flex sm:items-start sm:space-y-0 sm:space-x-10">
            {/* list payement method  */}
            {payementMethods.map((methode) => (
              <div key={methode.id} className="flex items-start">
                <input
                  onChange={handleChangeMethodePayement}
                  id={methode.id}
                  name="operator"
                  type="radio"
                  // disabled={methode.id === "posa"}
                  value={methode.value}
                  defaultChecked={methode.id === "om"}
                  className="h-4 w-4 border-gray-300 text-secondary-600 focus:ring-secondary-500"
                />
                <label
                  htmlFor={methode.id}
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  <figure>
                    <figcaption>{methode.title}</figcaption>
                    {methode.img}
                  </figure>
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default PayementStep;
