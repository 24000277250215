/**
 * @author assan zidan nsangou
 * @email nsangouassanzidan@gmail.com
 * @description consultation channel
 * @component Canaux
 */
import React, { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "../../../../UI/svg";
import { useDispatch } from "react-redux";
import {
	canalConsultation,
	resetConsultation,
	typeConsultation,
	villeConsultation,
} from "../../../../features/consultation/consultation.slice";
import { useGetCanauxConsultationQuery } from "../../../../features/api/apiSlice";
import { Link, useLocation } from "react-router-dom";
import Spinner from "../../../../UI/spinner/Spinner";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const ADA = {
	id: "5",
	name: "ADA",
};

const Canaux = () => {
	// channel state
	const [canal, setCanal] = useState();

	const [data, setData] = useState([ADA]);

	const location = useLocation();
	const dispatch = useDispatch();

	const { search } = location;
	const query = new URLSearchParams(search);
	const ref = query.get("ref");

	// get channel request
	const {
		data: canaux,
		isLoading: canauxLoading,
		isSuccess: canauxIsSuccess,
	} = useGetCanauxConsultationQuery();

	useEffect(() => {
		dispatch(resetConsultation());
		if (ref) {
			dispatch(
				typeConsultation({
					type: "second avis",
					isSecondAdvice: true,
				})
			);
		}

		return () => {};
	}, [ref, dispatch]);

	useEffect(() => {
		if (canauxIsSuccess) {
			const newData = data.concat(canaux?.data);
			setData(newData);
			setCanal(data[0]);
			dispatch(
				canalConsultation({
					id: "2",
					name: "Présentiel",
				})
			);
		}
		return () => {};
	}, [canauxIsSuccess, canaux, dispatch]);

	// select channel
	const setSelectedHandleChange = (value) => {
		if (value.id === "5") {
			dispatch(villeConsultation({ nomVille: "Douala" }));
			setCanal(value);
			dispatch(
				canalConsultation({
					id: "2",
					name: "Présentiel",
				})
			);
		} else {
			setCanal(value);
			dispatch(canalConsultation(value));
		}
	};

	return (
		<>
			<div className="mx-auto max-w-3xl py-2 px-4 text-center sm:py-2 sm:px-6 lg:px-8">
				<h2 className="text-3xl font-bold tracking-tight text-primary-800 sm:text-4xl">
				Selectionner le type d'assurance
				</h2>
			</div>

			<div className="max-w-3xl mx-auto mt-8">
				{canauxLoading && (
					<Spinner className="h-10 w-10 text-primary-700 flex justify-center text-center " />
				)}
				{canauxIsSuccess && (
					<RadioGroup
						value={canal}
						defaultChecked={canal}
						defaultValue={canal}
						onChange={setSelectedHandleChange}>
						<div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
							{data.map((canal) => (
								<RadioGroup.Option
									key={canal.id}
									value={canal}
									className={({ checked, active }) =>
										classNames(
											checked
												? "border-transparent"
												: "border-gray-300",
											active
												? "border-primary-500 ring-2 ring-primary-500"
												: "",
											"relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
										)
									}>
									{({ checked, active }) => (
										<>
											<span className="flex flex-1">
												<span className="flex flex-col">
													<RadioGroup.Label
														as="span"
														className="block text-md font-medium text-gray-900">
														{canal.name}
													</RadioGroup.Label>
												</span>
											</span>
											<CheckCircleIcon
												className={classNames(
													!checked ? "invisible" : "",
													"h-5 w-5 text-primary-600"
												)}
												aria-hidden="true"
											/>
											<span
												className={classNames(
													active
														? "border"
														: "border-2",
													checked
														? "border-primary-500"
														: "border-transparent",
													"pointer-events-none absolute -inset-px rounded-lg"
												)}
												aria-hidden="true"
											/>
										</>
									)}
								</RadioGroup.Option>
							))}
						</div>
					</RadioGroup>
				)}

				<div className="flex justify-end mt-6">
					{canal?.id === "5" ? (
						<Link
							to={`/consultations/assurance-ada`}
							disabled
							className="flex justify-center rounded-md border border-transparent bg-primary-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
							{" "}
							Continuer
						</Link>
					) : (
						<Link
							to={`/consultations/ville?canal=${canal?.name}`}
							disabled
							className="flex justify-center rounded-md border border-transparent bg-primary-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
							{" "}
							Continuer
						</Link>
					)}
				</div>
			</div>
		</>
	);
};

export default Canaux;
